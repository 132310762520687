/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './refund.scss';
import { Divider } from '@material-ui/core';
import {
  Done,
  DoneAll,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import RefundConfirm from './components/RefundConfirm';
import RefundFde from './components/RefundFde';
import RefundProducts from './components/RefundProducts';
import RefundTabs from './components/RefundTabs';

import { tableHelper } from '../../../../helpers';

const RefundConstructor = ({
  authUser,
  dispatchChangeField,
  dispatchFetchFde,
  dispatchResetOrigin,
  fde,
  loading,
  modal,
  navTypes,
  refundAll,
  table,
  orderSelected,
}) => {
  const [tab, setTab] = React.useState(0);
  const { selectedCustomer } = useSelector(state => state.customer);
  const [openFde, setOpenFde] = React.useState(false);
  const [fluid, setFluid] = React.useState([]);
  const [toDeliver, setToDeliver] = React.useState(0.0);
  const [methods, setMethods] = React.useState([]);
  const { user: { metodos_pagos, usable_currencies } } = authUser;

  const initFluid = (_fluid) => {
    const _toDeliver = Object.keys(orderSelected).length > 0
      ? parseFloat(orderSelected.order.tfde_pagado)
      : tableHelper.getRefundBalance(_fluid);
    const arr = _fluid.reverse();
    let _methods;
    if (_fluid && _fluid.length) {
      const fdeRewardsIndex = _fluid.findIndex(f => f.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA' && f.tfde_tipo !== 'Egreso');
      const methodRewardsIndex = metodos_pagos.findIndex(m => m.method === 'LBL_PUNTOS_RECOMPENSA');
      if (fdeRewardsIndex !== -1 && methodRewardsIndex === -1) {
        const rewardsPointMethod = {
          method: 'LBL_PUNTOS_RECOMPENSA',
          key: metodos_pagos[metodos_pagos.length - 1].key + 1,
          body: [
            {
              crmid: selectedCustomer.crmid,
              currency_code: _fluid[fdeRewardsIndex].currency_code,
              currency_id: _fluid[fdeRewardsIndex].currency_id,
              entity_label: 'Puntos Recompensa',
              referencia: 'Puntos Recompensa',
              tcyb_tipo: 'Puntos Recompensa',
            },
          ],
        };

        // const metodos = [...metodos_pagos, rewardsPointMethod];
        metodos_pagos.push(rewardsPointMethod);
        _methods = tableHelper.getRefundMethods(_toDeliver, arr, metodos_pagos, usable_currencies);
      } else {
        _methods = tableHelper.getRefundMethods(_toDeliver, arr, metodos_pagos, usable_currencies);
      }
    }
    /* const findPuntosIndex = _methods.findIndex(m => m.metodo === 'LBL_PUNTOS_RECOMPENSA');
    if (findPuntosIndex !== -1) {
      _methods[findPuntosIndex].sub.crmid = selectedCustomer.crmid;
      _methods[findPuntosIndex].metodo = 'Puntos Recompensa';
    } */
    setMethods(_methods);
    setToDeliver(_toDeliver);
    setFluid(_fluid.reverse());
  };

  const setOrigin = () => {
    dispatchResetOrigin();
    initFluid(fde);
  };

  const setUniqueMethod = (isRewardsPoints = false) => {
    const _methods = tableHelper.setUniqueMethod(metodos_pagos, table.items, methods, usable_currencies, fde, isRewardsPoints);
    setMethods(_methods);
  };

  const selectMethod = (key, id) => {
    // debugger;
    const _methods = tableHelper.setMethod(key, metodos_pagos, methods, id);
    if (_methods.length === 1 && _methods[0].edit) {
      if (_methods[0].metodo === 'LBL_PUNTOS_RECOMPENSA') {
        setUniqueMethod(true);
      } else {
        // setUniqueMethod();
        setMethods(_methods);
      }
    } else {
      setMethods(_methods);
    }
  };

  const selectSub = (id, tcybid) => {
    const _methods = tableHelper.setSub(methods, id, tcybid, usable_currencies);
    setMethods(_methods);
  };

  const selectSingle = (id) => {
    const _methods = methods.map((x) => {
      const el = { ...x };
      if (x.id === id) el.select = !x.select;
      return el;
    });
    setMethods(_methods);
  };

  const selectAndUnSelectAll = () => {
    const select = methods.every(x => x.select === false);
    const _methods = methods.map(x => ({ ...x, select }));
    setMethods(_methods);
  };

  const joinSelection = () => {
    if (methods.filter(x => x.select === true).length > 1) {
      const _methods = tableHelper.joinSelection(methods, usable_currencies);
      setMethods(_methods);
    }
  };

  const onChangeMonto = (obj) => {
    const _methods = tableHelper.changeMonto(methods, obj);
    setMethods(_methods);
  };

  const returnSelection = () => {
    if (tab === 0) initFluid(fde);
    else setUniqueMethod();
  };

  React.useEffect(() => {
    if (!loading) initFluid(fde);
    // eslint-disable-next-line
  }, [loading]);

  React.useEffect(() => {
    if (modal) {
      setTimeout(() => {
        dispatchFetchFde();
      }, 500);
    }
    // eslint-disable-next-line
  }, [modal]);

  React.useEffect(() => {
    if (tab === 1) { setUniqueMethod(); }
    // eslint-disable-next-line
  }, [table]);

  const tanOnChange = async (e, newValue) => {
    if (newValue === 0) setOrigin();
    else {
      await resetItemsAmount();
      setUniqueMethod();
    }
    setTab(newValue);
  };

  const setFieldChange = (crmdi, value) => {
    dispatchChangeField({ crmdi, value });
  };


  const resetItemsAmount = () => {
    table.items.map((item) => {
      setFieldChange(item.crmid, 0);
    });
  };

  const onSubmit = () => {
    const referencia = table.ref;
    const payments = methods.filter(x => x.select).map(x => ({ ...x, referencia }));
    const crmid = table.toSell;
    const all = Boolean(tab === 0);
    /*     // Get series
    const treebeslotes = {};
    table.items.forEach((product) => {
      if (product.pl_products_batch_status === 'pl_products_batch_status_serial' && product.TreebesLotes && product.TreebesLotes.length > 0) {
        treebeslotes[product.crmid] = {
          id: [],
          number: [],
          quantity: [],
        };

        product.TreebesLotes.forEach((serie) => {
          treebeslotes[product.crmid].id.push(serie.treebeslotesid);
          treebeslotes[product.crmid].number.push(serie.number);
          treebeslotes[product.crmid].quantity.push(serie.processed_quantity);
        });
      }
    }); */

    refundAll({ payments, crmid }, all);
  };
  const { items } = table;
  const enable = Boolean((methods && methods.some(x => x?.select)) || items.some(x => x?.field > 0));

  useEffect(() => {
    if (table.changeProducts === 'true') {
      setTab(1);
    }
  }, [table]);

  return (
    <div className="refund_container" style={{ overflow: 'auto' }}>
      <RefundTabs
        navTypes={navTypes}
        tab={tab}
        tanOnChange={tanOnChange}
      />
      <div className="refund_layout">
        {
          table.changeProducts !== 'true' && (
            <RefundFde
              fluid={fluid}
              joinSelection={joinSelection}
              loading={loading}
              methods={methods}
              metodos_pagos={metodos_pagos}
              modal={modal}
              openFde={openFde}
              toDeliver={toDeliver}
              returnSelection={returnSelection}
              selectAndUnSelectAll={selectAndUnSelectAll}
              selectMethod={selectMethod}
              selectSingle={selectSingle}
              selectSub={selectSub}
              setOpenFde={setOpenFde}
              onChangeMonto={onChangeMonto}
              tab={tab}
            />
          )
        }
        <Divider className="w-100 my-1" />
        <RefundProducts
          items={items}
          loading={loading}
          setFieldChange={setFieldChange}
          tab={tab}
        />
        <RefundConfirm
          tab={tab}
          onSubmit={onSubmit}
          loading={loading}
          disabled={!enable}
        />
      </div>
    </div>
  );
};

RefundConstructor.propTypes = {
  authUser: PropTypes.object.isRequired,
  orderSelected: PropTypes.object.isRequired,
  dispatchChangeField: PropTypes.func.isRequired,
  dispatchFetchFde: PropTypes.func.isRequired,
  dispatchResetOrigin: PropTypes.func.isRequired,
  fde: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.bool.isRequired,
  navTypes: PropTypes.array,
  refundAll: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
};

RefundConstructor.defaultProps = {
  navTypes: [
    {
      label: 'Total',
      icon: <DoneAll />,
      tooltip: 'Devolución total de productos y flujos de efectivo, al finalizar la orden se cancelará',
    },
    {
      label: 'Parcial',
      icon: <Done />,
      tooltip: 'Devolución parcial, esta opción habilita las cantidades a devolver al igual que el monto. Esta opción no cancela la orden',
    },
  ],
};

export default RefundConstructor;
