/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AppBar, Button, IconButton, LinearProgress, Paper, Toolbar, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Close, Save } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatMoney } from '../../../../helpers/tools';
import { addProductToCart } from '../../../../redux/actions/cartActions';
import { haveStock } from '../../../../helpers/product';
import ProductAPI from '../../../../api/ProductAPI';

const ProductOptionList = ({ product, handleClose }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [subProducts, setSubProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (item) => {
    // Check if item exists in selectedOptions
    if (selectedOptions[item.crmid]) {
      // If exists, remove it
      const { [item.crmid]: removed, ...rest } = selectedOptions;
      setSelectedOptions(rest);
    } else {
      // If not, add it
      setSelectedOptions({ ...selectedOptions, [item.crmid]: { ...item, quantity: 1 } });
    }
  };

  const addVariationAmount = (crmid, amount) => {
    // Add or remove quantity to variation
    const newQuantity = selectedOptions[crmid].quantity ? selectedOptions[crmid].quantity + amount : 1;
    // Check if new quantity is less than 1
    if (newQuantity < 1) {
      // If it is, remove variation from selectedOptions
      const { [crmid]: removed, ...rest } = selectedOptions;
      setSelectedOptions(rest);
    } else {
      // If not, update quantity
      setSelectedOptions({ ...selectedOptions, [crmid]: { ...selectedOptions[crmid], quantity: newQuantity } });
    }
  };

  const addProduct = (crmid, parentid, quantity) => {
    dispatch(addProductToCart(parentid, crmid, quantity));
  };

  const handleAddSingleProduct = (crmid, parentid, quantity) => {
    addProduct(crmid, parentid, quantity);
    handleClose();
  };

  const handleSave = () => {
    if (Object.keys(selectedOptions).length === 0) return;

    Object.keys(selectedOptions).forEach(async (key) => {
      // Check stock
      const hayStock = haveStock(selectedOptions[key], authUser);
      if (hayStock) {
        const {
          crmid, parentid, tpoc_id, quantity,
        } = selectedOptions[key];
        await addProduct(crmid, parentid || tpoc_id, quantity);
      }
    });
    handleClose();
  };

  const getProduct = async () => {
    setIsLoading(true);
    const productApi = new ProductAPI();
    const response = await productApi.get(product.crmid);
    if (response.success && response.result && Object.keys(response.result).length) {
      if (response.result[product.crmid] && Object.values(response.result[product.crmid]?.Products).length) {
        const productsExtracted = Object.values(response.result[product.crmid]?.Products).map(item => ({
          ...item,
          tpoc_id: product.crmid,
        }));
        setSubProducts(productsExtracted);
      } else {
        toast.warn('No se encontraron opciones para este producto');
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error('Error al obtener las opciones del producto');
    }
  };

  useEffect(() => {
    getProduct();
  }, [product]);

  return (
    <div>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton
            color="inherit"
            onClick={() => handleClose()}
            aria-label="Close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Opciones de
            {' '}
            {product?.entity_label}
          </Typography>
          <Button
            color="inherit"
            disabled={Object.keys(selectedOptions).length === 0}
            onClick={() => handleSave()}
          >
            <Save />
          </Button>
        </Toolbar>
        {
          isLoading && (<LinearProgress />)
        }
      </AppBar>
      <div
        style={{
          width: '100%',
          maxWidth: '70%',
          margin: '30px auto',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        {!isLoading && subProducts.map(item => (
          <div
            key={item.crmid}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 5,
              marginBottom: 10,
            }}
          >
            <Paper
              key={item.crmid}
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 5,
                width: '100%',
                backgroundColor: parseFloat(item.qtyinstock).toFixed(2) > 0 ? 'transparent' : '#F05B7026',
                // background: selectedOptions[item.crmid] && selectedOptions[item.crmid].crmid === item.crmid ? 'linear-gradient(to top right,#7f50a0 0,#636cb2 25%,#00a4e5)' : 'transparent',
              }}
              onClick={() => handleSelect(item)}
            >
              <div>
                <img
                  style={{ width: '80px', height: '80px' }}
                  src={item.image ? item.image : `${process.env.PUBLIC_URL}/img/no_image.jpg`}
                  alt=""
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 5,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', padding: 10 }}>
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                  }}
                  >
                    <div>
                      <p>{`${product.entity_label} - ${item.tpo_id_label}`}</p>
                      <span>
                        {formatMoney(item.unit_price)}
                        {' '}
                        - Con impuestos:
                        {' '}
                        {formatMoney(item.unit_price_included_tax)}
                      </span>
                      {' '}
                      - SKU:
                      {' '}
                      <span>{item?.productcode}</span>
                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    }}
                    >
                      <span>{parseFloat(item.qtyinstock).toFixed(2)}</span>
                      <span>STOCK</span>
                    </div>
                  </div>
                </div>
                {
                  selectedOptions[item.crmid] && selectedOptions[item.crmid].crmid === item.crmid && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      gap: 5,
                      marginRight: 20,
                    }}
                  >
                    <Button
                      style={{ minWidth: 25, width: 25, height: 25 }}
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        addVariationAmount(item.crmid, -1);
                      }}
                    >
                      -
                    </Button>
                    <input
                      style={{ width: '50px' }}
                      type="number"
                      value={selectedOptions[item.crmid].quantity}
                      onClick={e => e.stopPropagation()}
                      onFocus={e => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        const newQuantity = parseInt(e.target.value, 10);
                        if (!Number.isNaN(newQuantity)) {
                          setSelectedOptions(prevOptions => ({
                            ...prevOptions,
                            [item.crmid]: {
                              ...prevOptions[item.crmid],
                              quantity: newQuantity,
                            },
                          }));
                        }
                      }}
                    />
                    <Button
                      style={{ minWidth: 25, width: 25, height: 25 }}
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.qtyinstock === 0 || item.qtyinstock <= selectedOptions[item.crmid].quantity) return;
                        addVariationAmount(item.crmid, 1);
                      }}
                    >
                      +
                    </Button>
                  </div>
                  )
                }
              </div>
            </Paper>
            {
                  !selectedOptions[item.crmid] && selectedOptions[item.crmid] !== item.crmid && (
                  <div>
                    <Button
                      style={{ width: '100%', height: '80px', fontSize: 40 }}
                      variant="contained"
                      color="primary"
                      disabled={Object.keys(selectedOptions).length > 0}
                      onClick={() => handleAddSingleProduct(item.crmid, product.crmid, 1)}
                    >
                      +
                    </Button>
                  </div>
                  )
              }
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductOptionList;
