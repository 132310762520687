/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  ClearRefinements,
  HierarchicalMenu,
  RefinementList,
} from 'react-instantsearch';

import { useSelector } from 'react-redux';
import PriceSlider from '../slider/price-slider';
import useOnClickOutside from '../../../../hooks/useClickOutside';

const titleStyle = {
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 'bold',
  borderBottom: '2px solid #24a2db',
};

const ProductFilters = () => {
  const [open, setOpen] = useState(false);
  const authUser = useSelector(state => state.authUser);
  const ref = useRef();
  const { user: { filters, filters_order, meilisearch_attributes } } = authUser;
  useOnClickOutside(ref, () => setOpen(false));

  const renderAttributes = () => meilisearch_attributes.map(attribute => (
    <>
      <h5 style={titleStyle}>{attribute}</h5>
      <RefinementList attribute={`attr_${attribute}`} operator="or" />
    </>
  ));

  const renderFilterbyOrder = () => {
    if (!filters_order) {
      return null;
    }

    return filters_order.map(filter => (
      <>
        {
          filter === 'brands' && filters[filter] && filters[filter] === '1' && (
            <>
              <h5 style={titleStyle}>{filters[`${filter}_label`] || 'Marca'}</h5>
              <RefinementList attribute="marca" searchablePlaceholder="Marca" sortBy={['marca:desc']} searchable />
            </>
          )
        }
        {
          filter === 'prices' && filters[filter] && filters[filter] === '1' && (
            <>
              <h5 style={titleStyle}>{filters[`${filter}_label`] || 'Precio'}</h5>
              <PriceSlider attribute="tpv_unit_price" symbol="$" />
            </>
          )
        }
        {
          filter === 'discounts' && filters[filter] && filters[filter] === '1' && (
            <>
              <h5 style={titleStyle}>{filters[`${filter}_label`] || 'Descuento'}</h5>
              <PriceSlider attribute="discount" symbol="%" position="back" />
            </>
          )
        }
        {
          filter === 'categories' && filters[filter] && filters[filter] === '1' && (
            <>
              <h5 style={titleStyle}>{filters[`${filter}_label`] || 'Categoria'}</h5>
              <HierarchicalMenu
                attributes={[
                  'hierarchical_categories.lvl0',
                  'hierarchical_categories.lvl1',
                  'hierarchical_categories.lvl2',
                ]}
                showMore
              />
            </>
          )
        }
        {
          filter === 'attributes' && meilisearch_attributes && meilisearch_attributes.length > 0 && filters[filter] === '1' && (
            <>
              {renderAttributes()}
            </>
          )
        }
      </>
    ));
  };


  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.addEventListener('openMyFilters', toggleOpen);

    return () => {
      window.removeEventListener('openMyFilters', toggleOpen);
    };
  }, [open]);

  return (
    <>
      {
      open && <div className="left-panel-overlay" />
    }
      <div
        ref={ref}
        className="left-panel"
        style={{
          transform: !open ? 'translate(-335px)' : 'translate(-15px)', zIndex: 999, position: 'absolute', top: 0,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ClearRefinements translations={{
            resetButtonText: 'Borrar filtros',
          }}
          />
          <IconButton onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '16px' }} />
          </IconButton>
        </div>
        {renderFilterbyOrder()}
      </div>
    </>
  ); };

export default ProductFilters;
