/* eslint-disable semi */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ProductAPI from '../../../../api/ProductAPI';
import { useSelector } from 'react-redux';

export const TriggerType = Object.freeze({
  MENU_ITEM_ICON: 'MenuItemIcon',
  BUTTON: 'button',
  LIST_ITEM: 'ListItem',
});

const ProductLocationModal = ({
  product = null, updateProductLocation = () => {}, triggerType = TriggerType.MENU_ITEM_ICON,
}) => {
  const productApi = new ProductAPI();
  const store = useSelector(state => state.authUser.user.config.store);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef(null);

  const addBarCode = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendBarCode = async () => {
    const productid = product?.crmid || product?.id || product?.productid;
    if (!inputRef.current.value) {
      toast.error('Debes ingresar una ubicación');
      return;
    }
    if (product?.codigo_barras === inputRef.current.value) {
      toast.error('La ubicación ingresado es igual al actual');
      return;
    }

    if (!store.crmid) {
      toast.error('No se ha seleccionado un almacén');
      return;
    }

    if (!productid) {
      toast.error('No se ha seleccionado un producto');
      return;
    }

    setIsLoading(true);
    const response = await productApi.changeProductLocation(productid, store.crmid, inputRef.current.value);
    const { success } = response;
    if (success) {
      // Update product in the list
      updateProductLocation(product, inputRef.current.value);
      setOpen(false);
      inputRef.current.value = '';
      toast.success('Localización actualizada');
    } else {
      toast.error('No se pudo actualizar la ubicación');
    }
    setIsLoading(false);
  };

  async function getProductLocation() {
    setIsLoading(true);
    const productid = product?.crmid || product?.id || product?.productid;
    const response = await productApi.getProductLocation(productid, store.crmid);
    if (response.success && typeof response.result === 'string') {
      inputRef.current.value = response.result;
      setIsLoading(false);
      return;
    }
    toast.error('No se pudo obtener la ubicación del producto');
    setIsLoading(false);
  }

  useEffect(() => {
    if (open && product?.location && inputRef.current) {
      inputRef.current.value = product.location;
      inputRef.current.focus();
    }
  }, [product, open]);

  useEffect(() => {
    if (open) {
      getProductLocation();
    }
  }, [open]);

  return (
    <>
      {triggerType === TriggerType.BUTTON && (
      <Button onClick={addBarCode}>
        <FontAwesomeIcon icon="search-location" style={{ marginRight: 8 }} />
        Ubicación
      </Button>
      )}

      {triggerType === TriggerType.MENU_ITEM_ICON && (
        <Tooltip
          title="Ubicación"
          placement="left"
        >
          <span>
            <IconButton
              id="action_control_see"
              onClick={addBarCode}
              className="pos_menu_setDir"
            >
              <FontAwesomeIcon
                icon="search-location"
                style={{ width: '21px', heigth: '21px' }}
              />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {/* Modal */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">
          Ubicación
        </DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          }}
          >
            <div>
              <p style={{ fontSize: 15 }}>
                Producto:
              </p>
              <p style={{ fontSize: 14 }}>
                {product?.producto || product.productname}
              </p>
            </div>
            <div>
              <p style={{ fontSize: 15 }}>Ubicación:</p>
              <div style={{
                border: '1px solid #d8d8d8',
                borderRadius: 5,
                display: 'flex',
                overflow: 'hidden',
              }}
              >
                <input
                  style={{
                    width: '100%',
                    height: 36,
                    border: 0,
                    paddingLeft: 10,
                    outline: 'none',
                  }}
                  value={product?.location}
                  disabled={isLoading}
                  ref={inputRef}
                  type="text"
                  placeholder="Agrega la ubicación"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            isLoading ? (
              <Collapse in style={{ width: '100%' }}>
                <LinearProgress />
              </Collapse>
            ) : (
              <>
                <Button onClick={handleClose}>
                  Cancelar
                </Button>
                <Button onClick={sendBarCode} color="primary">
                  Guardar
                </Button>
              </>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
ProductLocationModal.propTypes = {
  product: PropTypes.object.isRequired,
  updateProductLocation: PropTypes.func.isRequired,
  triggerType: PropTypes.string.isRequired,
};

export default ProductLocationModal;
