/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import 'instantsearch.css/themes/algolia-min.css';
import {
  Configure,
  InstantSearch,
} from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ProductHeader from './components/product-header';
import ProductFilters from './components/product-filters';
import './post-v2.styles.scss';
import ProductContainer from './components/ProductContainer';
import ProductsSubHeader from './components/ProductsSubHeader';
import { changeMeiliIndex, removePricebook } from '../../redux/actions/priceBookActions';
import { setStorage } from '../../helpers/pricebook';
import search from '../../helpers/meili-search';

const PostV2 = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const { selectedCustomer } = useSelector(state => state.customer);
  const { user: { config: { store }, almacenes } } = authUser;
  const pricebookStore = useSelector(state => state.pricebook);
  const { activePriceBook, isIndexing } = pricebookStore;
  const [searchIndex, setSearchIndex] = useState(`${authUser.user.comercia_cuenta}_${activePriceBook.crmid || 0}`);

  const handleStorePriceBookChange = () => {
    // debugger;
    const selectedStore = almacenes.find(a => a.crmid === store.crmid);
    // Prioridad para lista precio
    // 0. Lista precio seleccionada del listado
    if (activePriceBook && activePriceBook.crmid && activePriceBook.crmid !== '0' && !activePriceBook.isCustomer) {
      setSearchIndex(`${authUser.user.comercia_cuenta}_${activePriceBook.crmid}`);
      dispatch(changeMeiliIndex(`${authUser.user.comercia_cuenta}_${activePriceBook.crmid}`));
      return;
    }

    // 1. Lista precio del cliente
    if (selectedCustomer.pricebookid && selectedCustomer.pricebookid !== '0') {
      setSearchIndex(`${authUser.user.comercia_cuenta}_${selectedCustomer.pricebookid}`);
      dispatch(changeMeiliIndex(`${authUser.user.comercia_cuenta}_${selectedCustomer.pricebookid}`));
      setStorage({ crmid: selectedCustomer.pricebookid, isCustomer: true });
      return;
    }
    // 2. Lista precio de la almacen

    if (selectedStore && selectedStore.pricebookid && selectedStore.pricebookid !== '0' && selectedCustomer.pricebookid !== '0') {
      setSearchIndex(`${authUser.user.comercia_cuenta}_${selectedStore.pricebookid}`);
      dispatch(changeMeiliIndex(`${authUser.user.comercia_cuenta}_${selectedStore.pricebookid}`));
      return;
    }
    // 3. default case
    if ((!selectedCustomer?.pricebookid || selectedCustomer?.pricebookid === '0') && (!selectedStore?.pricebookid || selectedStore?.pricebookid === '0')) {
      setSearchIndex(`${authUser.user.comercia_cuenta}_${activePriceBook?.crmid || 0}`);
      dispatch(changeMeiliIndex(`${authUser.user.comercia_cuenta}_${activePriceBook?.crmid || 0}`));
    }
  };

  useEffect(() => {
    handleStorePriceBookChange();
  }, [activePriceBook, store, selectedCustomer]);


  useEffect(() => {
    if (selectedCustomer.crmid === '54572') {
      dispatch(removePricebook());
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (isIndexing) {
      search.searchClient.clearCache();
    }
  }, [isIndexing]);

  return (
    <div className="pos-container">
      <div>
        <InstantSearch key={searchIndex} indexName={searchIndex} searchClient={search.searchClient}>
          <ProductHeader />
          <ProductsSubHeader searchIndex={searchIndex} />
          <ProductContainer searchIndex={searchIndex} />
          <ProductFilters />
          <Configure hitsPerPage={100} snippetEllipsisText="..." />
        </InstantSearch>
      </div>
      {
        isIndexing && (
          <div className="indexing-loader">
            <CircularProgress />
          </div>
        )
      }
    </div>
  );
};

export default PostV2;
