import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullDialog from '../../../components/FullDialog';
import { toggleModal } from "../../../redux/actions/modalActions";
import { LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Select from 'react-select';
import ExpensesAPI from "../../../api/ExpensesApi";
import ProductAPI from "../../../api/ProductAPI";
import MdaAPI from "../../../api/MdaApi";
import { toast } from "react-toastify";

const CartTransferModal = () => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const { user: { config: { store } } } = useSelector(state => state.authUser);
  const cart = useSelector(state => state.cart);
  const { products } = cart;

  const [comment, setComment] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [localProductsById, setLocalProductsById] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [almacenes, setAlmacenes] = useState([]);
  const [originStore, setOriginStore] = useState(null);
  const [productStock, setProductStock] = useState({});

  const dispatchCloseDeliverModal = () => {
    dispatch(toggleModal('cartTransfer'));
  };

  const resetToRequest = () => {
    setLocalProductsById(prev => Object.keys(prev).reduce((acc, key) => {
      acc[key] = { ...prev[key], toRequest: 0 };
      return acc;
    }, {}));
  };

  const getStores = async () => {
    setIsLoading(true);
    const storeApi = new ExpensesAPI();
    const response = await storeApi.getAlmacen();

    const { success, result } = response;
    if (success) {
      const publicStore = result.filter(s => /* s.public_inventory === '1' && */ s.transferencias_tpv === '1' && s.crmid !== store.crmid);
      if (publicStore.length === 0) {
        toast.error('No hay almacenes disponibles para solicitar transferencias');
        setIsLoading(false);
        return;
      }
      setAlmacenes(publicStore);
    }
    setIsLoading(false);
  };

  const getStoreStock = async () => {
    setIsLoading(true);
    const productsStock = {};
    const productApi = new ProductAPI();
    const allRequests = [];
    const productIds = products.map((item) => {
      const productid = (item.productid && item.productid.indexOf('x') !== -1)
        ? item.productid.split('x').pop()
        : item.crmid;
      return productid;
    });
    productIds.forEach((id) => {
      allRequests.push(productApi.getProduct(id));
    });
    const response = await Promise.all(allRequests);
    setIsLoading(false);
    response.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(item.result, `talm${originStore.value}_qtyinstock`)) {
        const quantity = Math.ceil(item.result[`talm${originStore.value}_qtyinstock`]);
        productsStock[item.result.id] = quantity;
      }
    });
    resetToRequest();
    setProductStock(productsStock);
    // Update to request base on quantity available
    products.forEach((item) => {
      const productid = (item.productid && item.productid.indexOf('x') !== -1)
        ? item.productid.split('x').pop()
        : item.crmid;
      setLocalProductsById(prev => ({ ...prev, [productid]: { ...prev[productid], toRequest: prev[productid].quantity > productsStock[productid] ? productsStock[productid] : prev[productid].quantity } }));
    });
  };

  const createTransfer = async () => {
    if (!originStore) {
      toast.error('No has seleccionado ningún almacén de origen');
      return;
    }
    const mdaApi = new MdaAPI();
    const ref = `TA-${new Date().getTime()}-1`;
    const toStore = store.crmid;
    const fromStore = originStore.value;
    const localProds = Object.values(localProductsById).filter(item => item.toRequest > 0);
    if (localProds.length === 0) {
      toast.error('No has seleccionado ninguna cantidad a solicitar');
      return;
    }
    const response = await mdaApi.createTransfer(ref, toStore, fromStore, comment, localProds, date);
    if (response.success) {
      toast.success('Transferencia creada correctamente');
      dispatchCloseDeliverModal();
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    if (modals?.cartTransferModalIsOpen) {
      getStores();
    }
  }, [modals?.cartTransferModalIsOpen]);

  useEffect(() => {
    if (originStore) {
      getStoreStock();
    }
  }, [originStore]);

  useEffect(() => {
    if (products.length > 0) {
      setLocalProductsById(products.reduce((acc, item) => {
        acc[item.crmid] = item;
        return acc;
      }, {}));
    }
  }, [products]);

  return (
    <FullDialog
      title="Solicitar transferencia"
      isOpen={modals?.cartTransferModalIsOpen}
      isLoading={isLoading}
      onClose={dispatchCloseDeliverModal}
      onSave={createTransfer}
      enableSave={products.length > 0}
    >
      <div style={{ maxWidth: 1200, width: '90%' }}>
        {isLoading && <LinearProgress />}
        <div className="form-section">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Referencia: </span>
              <input
                disabled
                type="text"
                className="deliver-input"
                value={`TA-${new Date().getTime()}-1`}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Fecha: </span>
              <input
                type="date"
                className="deliver-input"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Almacé destino: </span>
              <input
                disabled
                type="text"
                className="deliver-input"
                value={store.name}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Almacén origen: </span>
              <Select
                className="basic-single user_search"
                classNamePrefix="select"
                isClearable
                isSearchable
                name="usuarios"
                options={almacenes.map(item => ({
                  value: item.crmid,
                  label: item.almacen,
                }))}
                onChange={setOriginStore}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <div className="d-flex flex-column w-100">
              <span className="deliver-input-title">Comentario: </span>
              <textarea
                className="deliver-input"
                onChange={e => setComment(e.target.value)}
                rows="5"
                value={comment}
              />
            </div>
          </div>
        </div>
        <Paper className="mt-5" style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="deliver-table-cell"> Codigo </TableCell>
                <TableCell className="deliver-table-cell" align="center">
                  Producto
                </TableCell>
                <TableCell className="deliver-table-cell" align="center">
                  Cantidad
                </TableCell>
                <TableCell className="deliver-table-cell" align="center">
                  Disponible destino
                </TableCell>
                <TableCell className="deliver-table-cell" align="center">
                  Disponible origen
                </TableCell>
                <TableCell className="deliver-table-cell" align="center">
                  A solicitar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length > 0
                && products.map(item => (
                  <TableRow key={item.id}>
                    <TableCell className="deliver-table-cell">
                      {item.productcode}
                    </TableCell>
                    <TableCell
                      className="deliver-table-cell text-center"
                      component="th"
                      scope="row"
                    >
                      {item.productname}
                    </TableCell>
                    <TableCell className="deliver-table-cell text-center">
                      {parseFloat(item.quantity)}
                    </TableCell>
                    <TableCell className="deliver-table-cell text-center">
                      {parseFloat(item.qtyinstock)}
                    </TableCell>
                    <TableCell className="deliver-table-cell text-center">
                      {productStock[item.crmid] || 0}
                    </TableCell>
                    <TableCell className="deliver-table-cell text-center">
                      <input
                        style={{
                          border: '1px solid #cccccc',
                          padding: 8,
                          borderRadius: 5,
                        }}
                        type="number"
                        value={localProductsById[item.crmid]?.toRequest || 0}
                        max={localProductsById[item.crmid]?.quantity > productStock[item.crmid] ? productStock[item.crmid] : localProductsById[item.crmid]?.quantity || 0}
                        min={0}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (value > productStock[item.crmid] || value < 0) {
                            return;
                          }
                          setLocalProductsById(prev => ({
                            ...prev,
                            [item.crmid]: {
                              ...prev[item.crmid],
                              toRequest: value,
                            },
                          }));
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </FullDialog>
  );
};

export default CartTransferModal;
