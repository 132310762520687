/* eslint-disable no-restricted-syntax */
import fetch from 'cross-fetch';
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';

import { pricebookHelper } from '../helpers';

class ProductAPI extends ConfigurationAPI {
  getAll = async (page = 1, search = '') => {
    try {
      const pricebook = pricebookHelper.getStorage();
      const existPricebook = Boolean(Object.keys(pricebook).length > 0);
      if (!existPricebook) delete this.credentials.headers.pricebook;
      if (localStorage.getItem('currencyId')) { this.credentials.headers['currency-id'] = localStorage.getItem('currencyId'); }
      const response = await fetch(
        `${this.url}/list/TreebesProductosOC/?page=${page}&search=${search}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  get = async (crmid, withOutPriceList = false) => {
    try {
      // Delete pricebook from credentials when load products from default quote
      if (withOutPriceList) {
        delete this.credentials.headers.pricebook;
      }
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  getWithData = async (crmid, data) => {
    try {
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return { ...json, ...data };
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  getSingle = crmid => new Promise((resolve, reject) => {
    const config = {
      withCredentials: true,
      headers: this.credentials.headers,
    };
    axios
      .get(`${this.url}/id/${crmid}`, config)
      .then((result) => {
        const { data } = result;
        resolve(data);
      })
      .catch(err => reject(err.response));
  });

  getWithPricebook = async (crmid, pricebook) => {
    try {
      if (pricebook) this.credentials.headers.pricebook = pricebook;
      else delete this.credentials.headers.pricebook;
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  getProduct = async (crmid) => {
    try {
      const response = await fetch(
        `${this.url}/Product/${crmid}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  getFilteredProducts = async (page = 1, filters) => {
    try {
      const response = await fetch(
        `${this.url}/list/TreebesProductosOC/?page=${page}&search_col=${filters}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  productFastSearch = async (filters) => {
    try {
      const response = await fetch(
        `${this.url}/list/TreebesProductosOC/?search_fast=${JSON.stringify(
          filters,
        )}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  getPromotions = async (data) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(
        `${this.url}/TreebesPromociones/from-cart`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getProductsStock = async (data) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(
        `${this.url}/SalesOrder/get-quantities`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  getOrderDetail = async (
    returnrecord,
    aDocumentoId,
    almacenId,
    tipoMdA,
  ) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesMdA/QuickCreateAjax?returnrecord=${returnrecord}&a_documentoid=${aDocumentoId}&tipoMdA=${tipoMdA}&almacenid=${almacenId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };

  addProductBarCode = async (productId, barCode) => {
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify({
      crmid: productId,
      codigo_barras: barCode,
    });

    try {
      const response = await fetch(
        `${this.url}/Products`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  changeProductLocation = async (productId, storeId, location) => {
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify({
      location,
    });

    try {
      const response = await fetch(
        `${this.url}/Products/Location/${productId}/${storeId}`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  }

  existSerie = async (productid, lote) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesLotes/Serial/CheckIfExists/${productid}?number=["${lote}"]`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  }

  getLotesByOrderAndProduct = async (productid, purchaseorderid) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesLotes/Lot/Search/${productid}?q=busqueda&tipo_mda=EP&purchaseorderid=${purchaseorderid}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  }

  checkMeiliIndex = async (indexId) => {
    try {
      const response = await fetch(
        `${this.url}/meilisearch/${indexId}/status`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición para checar el indice: ${err}`,
      };
    }
  }

  getProductLocation = async (productId, storeId) => {
    try {
      const response = await fetch(
        `${this.url}/Products/Location/${productId}/${storeId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  }
}
/*
  getOrderDetail = async (
    returnrecord,
    aDocumentoId,
    almacenId,
    tipoMdA,
  ) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesMdA/QuickCreateAjax?returnrecord=${returnrecord}&a_documentoid=${aDocumentoId}&tipoMdA=${tipoMdA}&almacenid=${almacenId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };
} */

export default ProductAPI;
