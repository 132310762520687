import { find } from 'lodash';
import { convertAmountToSelectCurrency } from './tools';

/* eslint-disable no-plusplus */
export const genForm = ({ CRMID, EMPRESA }, typeAction, store, id) => {
  const toSell = CRMID;
  const account = EMPRESA;
  const move = typeAction === 'deliver' ? 'SC' : 'EC';
  const actual = new Date();
  const seconds = `0${actual.getSeconds()}`.slice(-2);
  const minutes = `0${actual.getMinutes()}`.slice(-2);
  const hours = `0${actual.getHours()}`.slice(-2);
  const day = `0${actual.getDate()}`.slice(-2);
  const month = `0${actual.getMonth() + 1}`.slice(-2);
  const year = actual.getFullYear();
  const date = `${year}-${month}-${day}`;
  const ref = `${move}-${year}${month}${day}${hours}${minutes}${seconds}-${id || '-1'}`;
  return {
    ref,
    store,
    toSell,
    date,
    account,
  };
};

export const getFieldToDeliver = (newProduct) => {
  const { toDeliver, qtyinstock } = newProduct;
  if (toDeliver > qtyinstock) {
    return qtyinstock !== 0 ? qtyinstock : 0;
  }
  return toDeliver;
};

export const transformOrder = (
  { PRODUCTOS, EXISTENCIAS, ALMACEN },
  typeAction,
  cartProductsByArray,
) => {
  const items = [];
  let enableAction = true;
  PRODUCTOS.forEach((producto) => {
    const arr = cartProductsByArray.filter((x) => {
      let productid = '';
      if (x.productid) {
        productid = x.productid.indexOf('x') !== -1
          ? x.productid.split('x').pop()
          : x.productid;
      }
      const crmid = x.crmid ? x.crmid : productid;
      return crmid === producto.id;
    });
    const arrPrice = [...arr.map(x => x.priceWithTaxes)];
    const price = Math.min(...arrPrice);
    const newProduct = {};
    newProduct.crmid = producto.id;
    newProduct.productname = producto.producto;
    newProduct.quantity = producto.cantidad;
    newProduct.price = price;
    newProduct.delivered = parseFloat(producto.entregados);
    newProduct.refunded = producto.devueltos;
    newProduct.toDeliver = parseFloat(producto['por entregar']);
    newProduct.toRefund = parseFloat(producto['por devolver']);
    newProduct.qtyinstock = parseFloat(EXISTENCIAS[ALMACEN][producto.id]);
    newProduct.limit = typeAction === 'deliver'
      ? getFieldToDeliver(newProduct)
      : newProduct.toRefund;
    newProduct.field = typeAction === 'deliver'
      ? getFieldToDeliver(newProduct)
      : newProduct.toRefund;
    newProduct.origin = typeAction === 'deliver'
      ? getFieldToDeliver(newProduct)
      : newProduct.toRefund;
    newProduct.sku = producto.codigo;

    if (producto.pl_products_batch_status && producto.TreebesLotes && producto.TreebesLotes.length > 0) {
      newProduct.TreebesLotes = producto.TreebesLotes || null;
      newProduct.pl_products_batch_status = producto.pl_products_batch_status;
    }

    items.push(newProduct);
  });
  const allEmpty = items.filter(newProduct => newProduct.field === 0);
  if (allEmpty.length === items.length) enableAction = false;
  return { items, enableAction };
};

export const transformTableItems = (
  json,
  typeAction,
  whareHouse,
  cartProductsByArray,
  discount,
  _balance,
  total,
  id,
) => {
  const newItems = transformOrder(json, typeAction, cartProductsByArray);
  const form = genForm(json, typeAction, whareHouse, id);
  const {
    ref, store, toSell, date, account,
  } = form;
  const { items, enableAction } = newItems;
  const totalDiscount = discount.percentage;
  const balance = parseFloat(_balance);
  return {
    items,
    ref,
    store,
    toSell,
    date,
    account,
    enableAction,
    totalDiscount,
    balance,
    total,
  };
};

export const fillFieldsItems = ([...array]) => {
  const items = [];
  let enableAction = true;
  array.forEach((item) => {
    const newItem = item;
    newItem.field = newItem.qtyinstock;
    items.push(newItem);
  });
  const allEmpty = items.filter(newProduct => newProduct.field === 0);
  if (allEmpty.length === items.length) enableAction = false;
  return { items, enableAction };
};

export const fillToDeliveredItems = ([...array]) => {
  const items = [];
  let enableAction = true;
  array.forEach((item) => {
    const newItem = item;
    if (item.toDeliver <= item.qtyinstock) {
      newItem.field = newItem.toDeliver;
    } else {
      newItem.field = newItem.qtyinstock;
    }
    items.push(newItem);
  });
  const allEmpty = items.filter(newProduct => newProduct.field === 0);
  if (allEmpty.length === items.length) enableAction = false;
  return { items, enableAction };
};

export const fillToRefundedItems = ([...array]) => {
  const items = [];
  let enableAction = true;
  array.forEach((item) => {
    const newItem = item;
    newItem.field = newItem.toRefund;
    if (newItem.field <= 0) enableAction = false;
    items.push(newItem);
  });
  return { items, enableAction };
};

export const emptyFieldsItems = ([...array]) => {
  const items = [];
  const enableAction = false;
  array.forEach((item) => {
    const newItem = item;
    newItem.field = 0;
    items.push(newItem);
  });
  return { items, enableAction };
};

/* Refund Controller */

export const getRefundBalance = (fluid) => {
  let res = 0.0;
  fluid.forEach((x) => {
    if (x.tfde_tipo === 'Ingreso') res += parseFloat(x.monto);
    else res -= parseFloat(x.monto);
  });
  return res;
};

export const getRefundMethods = (toDeliver, arr, metodosPagos, currencies) => {
  if (toDeliver > 0) {
    let _toRefund = [];
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      // eslint-disable-next-line no-continue
      if (element.tfde_tipo !== 'Ingreso') continue;
      else _toRefund = [..._toRefund, { ...element }];
    }
    if (_toRefund.length > 0) {
      // const montoArr = _toRefund.map(x => parseFloat(x.monto));
      const montoArr = _toRefund.map((x) => {
        const currency = currencies.find(c => c.id === x.currency_id);
        if (x.moneda_documento !== x.moneda_flujo) {
          const montoConverted = parseFloat(x.monto) / parseFloat(currency.conversion_rate);

          return montoConverted;
        }
        return parseFloat(x.monto);
      });
      const reduce = montoArr.reduce((a, c) => a + c);
      const mapping = _toRefund.map((x) => {
        const metodo = x.tfde_metodo;
        const method = metodosPagos.find(el => el.method === x.tfde_metodo);
        if (method === undefined || !method) { // -> used to remove invalid payment method
          return false;
        }
        let sub;
        if (x.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA' || x.tfde_metodo === 'Puntos Recompensa') {
          const findPuntosIndex = metodosPagos.findIndex(m => m.method === 'LBL_PUNTOS_RECOMPENSA');
          sub = {
            crmid: findPuntosIndex !== -1 ? metodosPagos[findPuntosIndex].body[0].crmid : x.crmid,
            currency_code: x.currency_code,
            currency_id: x.currency_id,
            entity_label: x.empresa_label,
            referencia: 'Puntos Recompensa',
            tcyb_tipo: 'Puntos Recompensa',
          };
        } else {
          sub = method.body.find(item => item.entity_label === x.cuenta) || metodosPagos[0].body[0];
        }

        const monto = parseFloat(x.monto);
        const tcybid = sub.crmid;
        return {
          metodo,
          method,
          sub,
          tcybid,
          monto,
          id: Math.random(),
          select: true,
          edit: false,
        };
      });
      /* if (reduce !== toDeliver) {
        const unique = {
          method: metodosPagos[0],
          metodo: metodosPagos[0].method,
          sub: metodosPagos[0].body[0],
          tcybid: metodosPagos[0].body[0].crmid,
          monto: toDeliver - reduce,
          id: Math.random(),
          select: true,
          edit: false,
        };
        mapping = [...mapping, unique];
      } */
      return mapping;
    }
    const unique = {
      method: metodosPagos[0],
      metodo: metodosPagos[0].method,
      sub: metodosPagos[0].body[0],
      tcybid: metodosPagos[0].body[0].crmid,
      monto: toDeliver,
      id: Math.random(),
      select: true,
      edit: false,
    };
    return [unique];
  }
  return [];
};

export const setMethod = (key, metodosPago, methods, id) => {
  const method = metodosPago.find(x => x.key === key);
  if (method) {
    let res = [];
    methods.forEach((met) => {
      let el = { ...met };
      if (el.id === id) {
        el = {
          id,
          method,
          metodo: method.method,
          sub: method.body[0],
          tcybid: method.body[0].crmid,
          monto: met.monto,
          select: met.select,
          edit: met.edit,
        };
      } /* else {
        el = {
          id,
          method,
          metodo: method.method,
          sub: method.body[0],
          tcybid: method.body[0].crmid,
          monto: met.monto,
          select: met.select,
          edit: met.edit,
        };
      } */
      res = [...res, el];
    });
    return res;
  }
  return [];
};

export const setSub = (methods, id, tcybid, currencies) => {
  let res = [];
  methods.forEach((met) => {
    let el = { ...met };
    if (el.id === id) {
      const { method: { body }, sub } = el;
      const currentCurrency = currencies.find(c => c.id === sub.currency_id);
      el = {
        ...el,
        sub: body.find(x => x.crmid === tcybid),
        tcybid,
      };
      const newCurrency = currencies.find(c => c.id === el.sub.currency_id);
      const montoConverted = convertAmountToSelectCurrency(currentCurrency.conversion_rate, newCurrency.conversion_rate, el.monto);
      el.monto = montoConverted;
    }
    res = [...res, el];
  });
  return res;
};

export const setUniqueMethod = (metodosPagos, items, methods, currencies, fde = [], isRewardsPoints = false) => {
  let monto = 0.0;

  // Convert all amount to first method currency
  const firstMethodCurrencyId = metodosPagos[0].body[0].currency_id;
  const firstMethodCurrency = currencies.find(c => c.id === firstMethodCurrencyId);

  if (items.length > 0) {
    monto = items.map(x => x.field * x.price).reduce((a, m) => a + m);
  } else {
    const sameCurrencyMontoArray = methods.map((x) => {
      if (firstMethodCurrencyId !== x.sub?.currency_id) {
        const selectionCurrency = currencies.find(c => c.id === x.sub?.currency_id);
        const montoConverted = convertAmountToSelectCurrency(selectionCurrency.conversion_rate, firstMethodCurrency.conversion_rate, x.monto);
        return montoConverted;
      }
      return parseFloat(x.monto);
    });

    monto = sameCurrencyMontoArray.reduce((a, m) => a + m, 0);
  }
  const method = {
    method: metodosPagos[0],
    metodo: metodosPagos[0].method,
    sub: metodosPagos[0].body[0],
    tcybid: metodosPagos[0].body[0].crmid,
    monto,
    id: Math.random(),
    select: true,
    edit: true,
  };


  if (isRewardsPoints && fde.length) {
    const findFdeIndex = fde.findIndex(f => f.tfde_tipo === 'Ingreso' && f.tfde_metodo === 'LBL_PUNTOS_RECOMPENSA');
    const findMethodIndex = metodosPagos.findIndex(m => m.method === 'LBL_PUNTOS_RECOMPENSA');
    if (findFdeIndex !== -1 || findMethodIndex !== -1) {
      method.monto = parseFloat(fde[findFdeIndex].monto);
      method.method = metodosPagos[findMethodIndex];
      // eslint-disable-next-line prefer-destructuring
      method.sub = metodosPagos[findMethodIndex].body[0];
      method.tcybid = metodosPagos[findMethodIndex].body[0].crmid;
      method.metodo = metodosPagos[findMethodIndex].method;
      method.select = true;
      method.edit = true;
    }
  }

  return [method];
};

export const joinSelection = (methods, currencies) => {
  const selection = methods.filter(x => x.select === true);
  // const monto = selection.reduce((a, c) => a + c.monto, 0);
  const newElement = {
    ...selection[0],
    id: Math.random(),
    // monto,
    select: false,
    edit: false,
  };

  // Calculate amount in one currency
  const newElementCurrency = currencies.find(c => c.id === newElement.sub.currency_id);
  const sameCurrencyMontoArray = selection.map((x) => {
    if (newElementCurrency.id !== x.sub.currency_id) {
      const selectionCurrency = currencies.find(c => c.id === x.sub.currency_id);
      const montoConverted = convertAmountToSelectCurrency(selectionCurrency.conversion_rate, newElementCurrency.conversion_rate, x.monto);
      return montoConverted;
    }
    return parseFloat(x.monto);
  });

  const monto = sameCurrencyMontoArray.reduce((a, c) => a + c, 0);
  newElement.monto = monto;

  const selectionIds = selection.map(x => x.id);
  let _methods = [];
  methods.forEach((x) => {
    const el = { ...x };
    if (!selectionIds.includes(x.id)) {
      _methods = [..._methods, el];
    }
  });
  return [..._methods, newElement];
};

export const changeMonto = (methods, obj) => {
  const res = methods.map((x) => {
    const el = { ...x };
    if (x.id === obj.id) el.monto = obj.value;
    return el;
  });
  return res;
};

export default {
  emptyFieldsItems,
  fillFieldsItems,
  fillToDeliveredItems,
  fillToRefundedItems,
  getRefundBalance,
  getRefundMethods,
  joinSelection,
  setMethod,
  setSub,
  transformOrder,
  transformTableItems,
  setUniqueMethod,
  changeMonto,
};
