/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import fetch from 'cross-fetch';
import { v4 as uuidv4 } from 'uuid';
import ConfigurationAPI from './ConfigurationAPI';

class MdaAPI extends ConfigurationAPI {
  get = async (id) => {
    const url = `${this.url}/list/TreebesMdA?related_to=${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al crear una dirección: ${getErr}`,
      };
    }
  };

  getPendingDelivery = async (id) => {
    const url = `${this.url}/TreebesMdA/PendingToDeliver/${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las entregas: ${getErr}`,
      };
    }
  };

  getPendingDeliveryDetail = async (almId, orderId) => {
    const url = `${this.url}/TreebesMdA/PendingToDeliver/${almId}/Detail/${orderId}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las entregas: ${getErr}`,
      };
    }
  };

  getProcessTransferDetail = async (almId, orderId) => {
    const url = `${this.url}/TreebesMdA/PendingToTransfer/${almId}/Detail?referencia=${orderId}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener el detalle de la transferencia: ${getErr}`,
      };
    }
  };

  deliverOrder = async (deliver) => {
    this.credentials.method = 'PUT';

    const data = {
      treebesmdaids: [],
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const product of deliver.data) {
      data.treebesmdaids.push(parseInt(product.treebesmdaid, 10));
    }

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/TreebesMdA/Process/PendingToDeliver`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  cancelDeliverOrder = async (deliverIdData) => {
    this.credentials.method = 'PUT';

    const data = {
      treebesmdaids: [],
      cancel: true,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const mdaId of deliverIdData) {
      data.treebesmdaids.push(parseInt(mdaId, 10));
    }

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/TreebesMdA/Process/PendingToDeliver`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  processTransfer = async (deliverId) => {
    this.credentials.method = 'POST';

    const data = {
      treebesmdaids: [...deliverId],
    };

    // eslint-disable-next-line no-restricted-syntax
    /* for (const product of deliver.data) {
      data.treebesmdaids.push(parseInt(product.treebesmdaid, 10));
    } */

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/TreebesMdA/Process/PendingToTransfer`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  deliverTransfer = async (deliverId) => {
    this.credentials.method = 'PUT';

    const data = {
      treebesmdaids: [...deliverId],
    };

    // eslint-disable-next-line no-restricted-syntax
    /* for (const product of deliver.data) {
      data.treebesmdaids.push(parseInt(product.treebesmdaid, 10));
    } */

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/TreebesMdA/Process/PendingToDeliver`,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  salesToDeliver = async (id) => {
    const url = `${this.url}/TreebesMdA/ToDeliver/${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las ventas por entregar: ${getErr}`,
      };
    }
  };

  salesToReceive = async (id) => {
    const url = `${this.url}/TreebesMdA/ToReceive/${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las ventas por recibir: ${getErr}`,
      };
    }
  };

  transfersToReceive = async (id) => {
    const url = `${this.url}/TreebesMdA/PendingToReceive/${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las transferencias por recibir: ${getErr}`,
      };
    }
  };

  transfersToProcess = async (id) => {
    const url = `${this.url}/TreebesMdA/PendingToTransfer/${id}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las transferencias por recibir: ${getErr}`,
      };
    }
  };

  transfersListToReceive = async (id, referencia) => {
    const url = `${this.url}/TreebesMdA/PendingToReceive/${id}/Detail?referencia=${referencia}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener las transferencias por recibir: ${getErr}`,
      };
    }
  };

  cancelToDeliver = async (storeId) => {
    const url = `${this.url}/TreebesMdA/PendingToDeliverToCancel/${storeId}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener los deliver por cancelar: ${getErr}`,
      };
    }
  };

  cancelToDeliverDetail = async (storeId, reference) => {
    const url = `${this.url}/TreebesMdA/PendingToDeliverToCancel/${storeId}/Detail?referencia=${reference}`;
    try {
      const response = await fetch(url, this.credentials);
      // console.log('responseeeee', response);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al obtener los deliver por cancelar: ${getErr}`,
      };
    }
  };

  setTransferDeliver = async (products) => {
    delete this.credentials.headers.pricebook;
    const subModule = 'TreebesMdA';
    this.credentials.method = 'POST';
    this.credentials.headers.Authorization = `Basic ${this.token}`;
    const URL = `${this.url}/${subModule}/Process/PendingToReceive`;

    let data = [
      ...products,
    ];

    // Filter qty > 0
    data = data.filter(p => p.qty > 0);

    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(URL, this.credentials);
      const json = await response.json();
      const message = json.success ? 'Movimiento de Almacen exitoso' : json.error.message;
      return { ...json, message };
    }
    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  deliver = async (products, referencia, storeId, orderId, clientId, date, comments = '', tipoMdA = '') => {
    delete this.credentials.headers.pricebook;
    const subModule = 'TreebesMdA';
    this.credentials.method = 'POST';
    this.credentials.headers.Authorization = `Basic ${this.token}`;
    const URL = `${this.url}/${subModule}`;

    const data = {
      tipoMdA,
      productos: products.map(x => ({ crmid: x.id, field: parseFloat(x.por_entregar) })),
      referencia,
      description: comments,
      fecha: date,
      de_documentoid: storeId,
      a_documentoid: orderId,
      empresaid: clientId,
    };

    // Filter field > 0
    data.productos = data.productos.filter(p => p.field > 0);

    // Add lotes
    const treebeslotes = {};
    products.map((p) => {
      if (p.TreebesLotes && p.TreebesLotes.length) {
        const loteObject = { id: [], number: [], quantity: [] };
        p.TreebesLotes.map((l) => {
          if (l.qty > 0) {
            loteObject.id.push(l.treebeslotesid);
            loteObject.number.push(l.number);
            loteObject.quantity.push(l.qty);
          }
        });
        if (loteObject.id.length > 0 && loteObject.number.length > 0 && loteObject.quantity.length > 0) {
          treebeslotes[p.id] = { ...loteObject };
        }
        // treebeslotes[p.id] = { ...loteObject };
      }
    });

    if (Object.keys(treebeslotes).length > 0) {
      data.treebeslotes = { ...treebeslotes };
    }

    this.credentials.headers['prevent-duplicate'] = uuidv4();
    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(URL, this.credentials);
      const json = await response.json();
      const message = json.success ? 'Movimiento de Almacen exitoso' : json.error.message;
      return { ...json, message };
    }
    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  receiver = async (products, referencia, storeId, orderId, clientId, date, comments = '', tipoMdA = '') => {
    delete this.credentials.headers.pricebook;
    const subModule = 'TreebesMdA';
    const treebeslotes = {};
    this.credentials.method = 'POST';
    this.credentials.headers.Authorization = `Basic ${this.token}`;
    const URL = `${this.url}/${subModule}`;

    products.map((p) => {
      treebeslotes[p.id] = {
        id: [],
        number: [],
        quantity: [],
      };
      for (const l of p.lotesSeries) {
        treebeslotes[p.id].id.push(1);
        treebeslotes[p.id].number.push(l.number);
        treebeslotes[p.id].quantity.push(l.qty);
      }
    });

    // Filter empty lotes
    for (const key in treebeslotes) {
      if (treebeslotes[key].id.length === 0 && treebeslotes[key].number.length === 0 && treebeslotes[key].quantity.length === 0) {
        delete treebeslotes[key];
      }
    }

    const data = {
      tipoMdA,
      productos: products.map(x => ({ crmid: x.id, field: parseFloat(x.por_recibir) })),
      referencia,
      description: comments,
      fecha: date,
      de_documentoid: orderId,
      a_documentoid: storeId,
      empresaid: clientId,
      treebeslotes,
    };

    // Filter field > 0
    data.productos = data.productos.filter(p => p.field > 0);

    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(URL, this.credentials);
      const json = await response.json();
      const message = json.success ? 'Movimiento de Almacen exitoso' : json.error.message;
      return { ...json, message };
    }
    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  createTransfer = async (ref, toStore, fromStore, comment, products, date) => {
    const subModule = 'TreebesMdA';
    this.credentials.method = 'POST';
    const URL = `${this.url}/${subModule}`;

    const data = {
      tipoMdA: 'TA',
      referencia: ref,
      description: comment,
      productos: products.map(x => ({ crmid: x.crmid, field: parseFloat(x.toRequest) })),
      de_documentoid: fromStore,
      a_documentoid: toStore,
      fecha: date,
    };

    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(URL, this.credentials);
      const json = await response.json();
      return json;
    }
    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default MdaAPI;
