/* eslint-disable semi */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ProductAPI from '../../../../api/ProductAPI';

const TriggerType = Object.freeze({
  MENU_ITEM_ICON: 'MenuItemIcon',
  BUTTON: 'button',
  LIST_ITEM: 'ListItem',
});

const CodigoBarraModal = ({
  product = null, updateBarCode = () => {}, triggerType = TriggerType.MENU_ITEM_ICON,
}) => {
  const productApi = new ProductAPI();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef(null);
  console.log(product);

  const addBarCode = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendBarCode = async () => {
    setIsLoading(true);
    const productid = product.crmid || product.id || product.productid;
    const response = await productApi.addProductBarCode(productid, inputRef.current.value);
    const { success } = response;
    if (success) {
      // Update product in the list
      updateBarCode(product, inputRef.current.value);
      setOpen(false);
      inputRef.current.value = '';
      toast.success('Código de barras agregado');
    } else {
      toast.error('No se pudo agregar el código de barra');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (open && product?.codigo_barras && inputRef.current) {
      inputRef.current.value = product.codigo_barras;
      inputRef.current.focus();
    }
  }, [product, open]);

  return (
    <>
      {triggerType === TriggerType.BUTTON && (
      <button
        onClick={addBarCode}
        style={{
          border: '1px solid gray',
          borderRadius: 10,
          width: '100%',
          marginTop: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 25,
          backgroundColor: 'transparent',
        }}
        type="button"
      >
        <FontAwesomeIcon icon="barcode" style={{ marginRight: 8 }} />
        {product && product.codigo_barras ? 'Cambiar código' : 'Agregar código'}
      </button>
      )}

      {triggerType === TriggerType.MENU_ITEM_ICON && (
        <Tooltip
          title={product && product.codigo_barras ? 'Cambiar código de barra' : 'Agregar código de barra'}
          placement="left"
        >
          <span>
            <IconButton
              id="action_control_see"
              onClick={addBarCode}
              className="pos_menu_setDir"
            >
              <FontAwesomeIcon
                icon="barcode"
                style={{ width: '21px', heigth: '21px' }}
              />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {/* Modal */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">
          {product && product.codigo_barras ? 'Cambiar código de barra' : 'Agregar código de barra'}
        </DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          }}
          >
            <div>
              <p style={{ fontSize: 15 }}>
                Producto:
              </p>
              <p style={{ fontSize: 14 }}>
                {product?.producto || product.productname}
              </p>
            </div>
            <div>
              <p style={{ fontSize: 15 }}>Código barra:</p>
              <div style={{
                border: '1px solid #d8d8d8',
                borderRadius: 5,
                display: 'flex',
                overflow: 'hidden',
              }}
              >
                <input
                  style={{
                    width: '100%',
                    height: 36,
                    border: 0,
                    paddingLeft: 10,
                    outline: 'none',
                  }}
                  defaultValue={product?.codigo_barras}
                  disabled={isLoading}
                  ref={inputRef}
                  type="text"
                  placeholder="Agrega el código de barra"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            isLoading ? (
              <Collapse in style={{ width: '100%' }}>
                <LinearProgress />
              </Collapse>
            ) : (
              <>
                <Button onClick={handleClose}>
                  Cancelar
                </Button>
                <Button onClick={sendBarCode} color="primary">
                  {product && product.codigo_barras ? 'Cambiar' : 'Agregar'}
                </Button>
              </>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
CodigoBarraModal.propTypes = {
  product: PropTypes.object.isRequired,
  updateBarCode: PropTypes.func.isRequired,
  triggerType: PropTypes.string.isRequired,
};

export default CodigoBarraModal;
