/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import OrderApi from '../../api/OrderAPI';
import ClientAPI from '../../api/ClientAPI';
import TableAPI from '../../api/TableAPI';
import FdeAPI from '../../api/FdeAPI';
import MdaAPI from '../../api/MdaApi';
import DownloadAPI from '../../api/DownloadAPI';
import TimbrarApi from '../../api/TimbrarApi';
import { loading, loaded } from './loadActions';
import { fetchTimbrado } from './TimbrarActions';
import { openModal, closeModal } from './modalActions';
import { orderHelper, toolsHelper, pricebookHelper } from '../../helpers';
import { setGeneralColumnDefs } from './authUserActions';
import { addDiscount, removeDiscount } from './discountActions';
import { fetchAllProducts } from './productActions';
import FinanceAPI from '../../api/FinanceAPI';
import {
  addOrderToCart,
  clearCart,
  alertProducts,
  evaluateStock,
  updatePromotions,
} from './cartActions';
import {
  selectCustomer,
  fetchAllCustomers,
  fetchAddressByCustomer,
} from './customerActions';
import { fetchAllCotizaciones, selectCotizacion } from './cotizarActions';
import appModules from '../../constant/appModules';


export const ADD_ORDERS = 'ADD_ORDERS';
export const CLEAR_MULTIFILTER_ORDER = 'CLEAR_MULTIFILTER_ORDER';
export const CONFIG_SELL_COLUMN_DEF = 'CONFIG_SELL_COLUMN_DEF';
export const DEFINE_COLUMN_ORDER = 'DEFINE_COLUMN_ORDER';
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const FETCH_FACTURAS = 'FETCH_FACTURAS';
export const FETCH_FDE = 'FETCH_FDE';
export const FETCH_FILTER = 'FETCH_FILTER';
export const FETCH_MDA = 'FETCH_MDA';
export const FILTER_ORDERS = 'FILTER_ORDERS';
export const GET_COLUMN_DEF_ORDER = 'GET_COLUMN_DEF_ORDER';
export const GET_FILES = 'GET_FILES';
export const GET_ORDERS_BY_PAGE = 'GET_ORDERS_BY_PAGE';
export const HANDLE_EDIT_ORDER = 'HANDLE_EDIT_ORDER';
export const ENABLE_EDIT_ORDER = 'ENABLE_EDIT_ORDER';
export const REMOVE_MULTIFILTER_ORDER = 'REMOVE_MULTIFILTER_ORDER';
export const RESET_COLUMN_DEF = 'RESET_COLUMN_DEF';
export const RESET_EDIT_ORDER = 'RESET_EDIT_ORDER';
export const SELECT_ORDER = 'SELECT_ORDER';
export const SET_COLUMN_DEF = 'SET_COLUMN_DEF';
export const SET_MULTIFILTER_ORDER = 'SET_MULTIFILTER_ORDER';
export const SET_PRESELECT_ORDER = 'SET_PRESELECT_ORDER';

export const SET_ALL_ORDERS_PAGES = 'SET_ALL_ORDERS_PAGES';
export const SET_CURRENT_PAGES = 'SET_CURRENT_PAGES';
export const SET_TOTAL_ORDERS = 'SET_TOTAL_ORDERS';
export const SET_ORDER_LISTS = 'SET_ORDER_LISTS';
export const SET_ORDER_VIEW_DATA = 'SET_ORDER_VIEW_DATA';
export const SET_ORDER_LIST_SELECTED = 'SET_ORDER_LIST_SELECTED';
export const PRINT_ORDER = 'PRINT_ORDER';

export function setAllOrdersPages(allPages) {
  return {
    allPages,
    type: SET_ALL_ORDERS_PAGES,
  };
}

export function setCurrentPage(currentPage) {
  return {
    currentPage,
    type: SET_CURRENT_PAGES,
  };
}

export function setTotalOrders(totalOrders) {
  return {
    totalOrders,
    type: SET_TOTAL_ORDERS,
  };
}

export function addOrders(orders) {
  return {
    type: ADD_ORDERS,
    orders,
  };
}

export function addPreselectOrder(order) {
  return {
    type: SET_PRESELECT_ORDER,
    order,
  };
}

export function setOrderLists(lists) {
  return {
    type: SET_ORDER_LISTS,
    lists,
  };
}

export function setOrderViewData(orderView) {
  return {
    type: SET_ORDER_VIEW_DATA,
    orderView,
  };
}

export function setOrderListSelected(orderList) {
  return {
    type: SET_ORDER_LIST_SELECTED,
    orderList,
  };
}

export function getOrdersByPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_ORDERS_BY_PAGE,
        page,
      });
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function fetchFilter() {
  return async (dispatch, getState) => {
    const { order } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({ type: FETCH_FILTER, order });
    } catch (err) {
      console.log('fetchFilter err: ', err);
    }
  };
}

export function setMultiFilterOrder(item) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: SET_MULTIFILTER_ORDER,
        filters,
        item,
      });
    } catch (err) {
      console.log('setMultiFilterOrder err: ', err);
    }
  };
}

export function removeMultiFilterOrder(word) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: REMOVE_MULTIFILTER_ORDER,
        filters,
        word,
      });
    } catch (err) {
      console.log('removeMultiFilter err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function clearAllFiltersOrder() {
  return {
    type: CLEAR_MULTIFILTER_ORDER,
  };
}

export function fetchOrderLists() {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    const { tabs: { posTypeTab } } = getState();
    const isOrder = posTypeTab === 'Historico';
    dispatch(loading('list'));
    const response = await orderApi.getList(isOrder);
    const { success, result } = response;

    if (success) {
      dispatch(setOrderLists(result));
    } else {
      toast.error('Error obteniendo las listas');
    }
    dispatch(loaded('list'));
  };
}

export function fetchOrderView(cvid, page = 1, searchParams = [], orderBy = 'createdtime', sortorder) {
  const order = orderBy || 'createdtime';
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    const { order: { lists: { default: cvidDefault } }, tabs: { posTypeTab }, authUser } = getState();
    const cvidList = cvid || cvidDefault;
    const isOrder = posTypeTab === 'Historico';
    const {
      user: {
        config: { store },
      },
    } = authUser;

    if (cvidList && (posTypeTab === 'Historico' || posTypeTab === 'Cotizaciones')) {
      dispatch(loading('list'));
      // Add store if not exists
      if (store && (!searchParams || !searchParams.length)) {
        searchParams.push(['treebesalmid', 'c', `${store.name}`]);
      }

      const response = await orderApi.getListView(isOrder, cvidList, page, searchParams, order, sortorder);
      const { success, result } = response;

      if (success) {
        dispatch(setOrderViewData(result));
      } else {
        toast.error('Error obteniendo la data de la lista seleccionada');
      }

      dispatch(loaded('list'));
    } else {
      console.log('No hay lista seleccionada');
    }
    dispatch(loaded('refreshOrders'));
  };
}

export function fetchAllOrders() {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    const { authUser } = getState();
    dispatch(loading('order'));
    dispatch({
      type: FETCH_ALL_ORDERS,
    });
    try {
      const { user: { config } } = authUser;
      const fde = orderHelper.getFdeFilters(config.pos_filtrofde || null, 'estado_fde[]=');
      const mda = orderHelper.getFdeFilters(config.pos_filtromda || null, 'estado_mda[]=');
      const sos = orderHelper.getStateFilters(config.pos_filtrostate || null);
      const response = await orderApi.getAll(fde, mda, sos);
      await dispatch(setCurrentPage(1));
      await dispatch(setAllOrdersPages(response.pages));
      await dispatch(setTotalOrders(response.count));
      dispatch(addOrders(response.result));
    } catch (err) {
      dispatch(addOrders([], authUser));
      toast.warn(`Error al obtener las ordenes de venta: ${err}`);
    } finally {
      dispatch(clearAllFiltersOrder());
      dispatch(loaded('order'));
    }
  };
}

export function fetchListOrders(search = '', page = 1, filters = null) {
  const financeApi = new FinanceAPI();
  return async (dispatch, getState) => {
    const { authUser } = getState();
    dispatch(loading('order'));
    dispatch(loading('oldOrder'));
    dispatch({
      type: FETCH_ALL_ORDERS,
    });
    try {
      let response;
      if (filters) {
        response = await financeApi.getFilteredOrders(filters);
      } else {
        response = await financeApi.getListSalesOrder(page, search);
      }
      await dispatch(setCurrentPage(page));
      await dispatch(setAllOrdersPages(response.pages));
      await dispatch(setTotalOrders(response.count));
      dispatch(addOrders(response.result));
    } catch (err) {
      dispatch(addOrders([], authUser));
      toast.warn(`Error al obtener las ordenes de venta: ${err}`);
    } finally {
      dispatch(clearAllFiltersOrder());
      dispatch(loaded('order'));
      dispatch(loaded('oldOrder'));
    }
  };
}

export function selectOrder(crmid) {
  return async (dispatch) => {
    const orderApi = new OrderApi();
    dispatch(loading('order'));
    dispatch(removeDiscount());
    try {
      let foundOrder = 'undefined';
      let foundCustomer = 'undefined';

      if (foundOrder === 'undefined') {
        const { result, success } = await orderApi.getOrder(crmid);
        if (success) {
          foundOrder = result;
        } else {
          toast.error('No se logró obtener las ordenes, intentelo mas tarde.');
          return;
        }
      }

      if (toolsHelper.stringIsEmpty(foundOrder.hdnDiscountAmount)
          || toolsHelper.stringIsEmpty(foundOrder.hdnDiscountPercent)) {
        const result = orderHelper.getDiscountStateFromOrder(foundOrder);
        dispatch(addDiscount(result));
      }

     //  dispatch({ type: SELECT_ORDER });
      await dispatch(addOrderToCart(foundOrder));

      const clientApi = new ClientAPI();
      const customerId = foundOrder.account_id.split('x').pop();
      const response = await clientApi.getAccount(customerId);
      const { success } = response;
      if (success) {
        foundCustomer = response.result;
        await dispatch(selectCustomer(foundCustomer, false));
      } else {
        await toast.success('Cliente encontrado sin RFC y sin correo electrónico');
        const { accountname } = foundOrder;
        foundCustomer = { accountname };
      }
    } catch {
      console.log('selectOrder ERROR: ');
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function filterOrder(word) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const { tabs } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      switch (tabs.posDisplayTab) {
        case 'grid':
          dispatch({
            type: FILTER_ORDERS,
            word,
          });
          break;
        case 'list':
          dispatch(setMultiFilterOrder({ title: 'search', value: word, type: 'search' }));
          break;
        default:
          dispatch({
            type: FILTER_ORDERS,
            word,
          });
      }
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded('order'));
    }
  };
}

/**
 * [setActivityToOrder Deliver or Refund a order in a lot of concepts: Wharehouse Moves, Money, others.]
 *
 * @param   {string}  crmid     [crmid Ordr CRMID
 * @param   {string}  activity  [activityThe actvity that should be done]
 *
 */
export function setActivityToOrder(crmid, activity, showT = true, refreshList = true) {
  const orderApi = new OrderApi();
  const tableApi = new TableAPI();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const {
      cart,
      table,
      timbrar: { makeBill },
      authUser,
      order: { orderData: { cvid } },
    } = getState();
    let response;
    try {
      switch (activity) {
        case 'Entregar':
          response = await tableApi.setMDA(table, cart, activity);
          break;
        case 'Entrega Externa':
          response = await tableApi.setMDAExternalDelivery(table, cart);
          break;
        case 'Devolver Dineros':
          response = await orderApi.refundOrder(crmid, 'money');
          break;
        case 'Devolver Productos':
          response = await tableApi.setMDA(table, cart, activity);
          break;
        default:
          return dispatch(loaded('order'));
      }
      if (response.success) {
        await dispatch(fetchAllCustomers());
        await dispatch(clearCart());
        if (refreshList) {
          await dispatch(fetchOrderView(cvid));
        }
        // await dispatch(fetchAllOrders());
        await dispatch(closeModal('deliver'));
        await dispatch(closeModal('refund'));
        if (showT) toast.success(` ✔  ${response.message} `);
        if (refreshList) {
          await dispatch(selectOrder(crmid));
        }
        if (activity === 'Entregar') {
          const { user: { config: { pos_checkout_bill } } } = authUser;
          const billConfig = Boolean(pos_checkout_bill === 'Siempre');
          const { modals } = getState();
          if (!modals.deliverModalIsOpen) {
            if (billConfig) {
              if (showT) toast.info('Facturación automatica posterior a la entrega');
              setTimeout(async () => {
                await dispatch(fetchTimbrado(crmid));
              }, 2000);
            } else if (makeBill) {
              if (showT) toast.info('Facturación posterior a la entrega');
              setTimeout(async () => {
                await dispatch(fetchTimbrado(crmid));
              }, 2000);
            }
          }
        }
      } else {
        toast.warn(`❌ ${response.message}`);
      }
    } catch (err) {
      toast.error(` ❌ Error - No se pudo completar el proceso: ${err}`);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function configSellColumnDef(field) {
  return async (dispatch, getState) => {
    await dispatch({
      type: CONFIG_SELL_COLUMN_DEF,
      field,
    });
    const { order: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, false));
  };
}

export function setColumnsDefs(defs) {
  return async (dispatch) => {
    await dispatch({
      type: SET_COLUMN_DEF,
      defs,
    });
    await dispatch(setGeneralColumnDefs(defs, false));
  };
}

export function resetColumnsDefs() {
  return async (dispatch) => {
    dispatch({ type: RESET_COLUMN_DEF });
  };
}

export function getConfigOrderDef(fetch = true) {
  return async (dispatch, getState) => {
    await dispatch({ type: GET_COLUMN_DEF_ORDER });
    const { order: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, false, fetch));
  };
}

export function changeOrderStatus(sostatus, showT = true, orden = null, fetchOrder = true) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      let ordenSelected = null;
      const { cart: { orderSelected: { order } }, order: { orderData: { cvid } } } = getState();

      if (orden !== null) {
        ordenSelected = orden;
      } else {
        ordenSelected = order;
      }
      // eslint-disable-next-line no-prototype-builtins
      const isQuote = !!(ordenSelected && (ordenSelected.hasOwnProperty('quote_no') || ordenSelected.hasOwnProperty('quotestage')));
      const { success, message, result } = await orderApi.setStatus(ordenSelected.crmid, sostatus, isQuote);
      if (success) {
        if (showT) toast.success(` ✔  ${message.success} `);
        await dispatch(clearCart());
        if (isQuote) {
          await dispatch(fetchAllCotizaciones());
          await dispatch(selectCotizacion(ordenSelected.crmid));
        } else {
          // await dispatch(fetchAllOrders());
          // await dispatch(fetchOrderView(cvid));
          if (fetchOrder) {
            await dispatch(selectOrder(ordenSelected.crmid));
          }
        }
      } else {
        await dispatch(evaluateStock());
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
      if (result && result.noHayDe && Object.keys(result.noHayDe).length > 0) {
        await dispatch(alertProducts(result.noHayDe));
      }
    } catch (error) {
      console.log('changeOrderStatus error: ', error);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function downloadFile(url, name) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const downloadApi = new DownloadAPI();
      await downloadApi.download(url, name);
    } catch (err) {
      console.log({ downloadFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function getFilesByOrder(page = 1, search = '') {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { crmid } = orderSelected;
        const response = await orderApi.getFiles(crmid, page, search);
        if (response.success) {
          await dispatch({
            type: GET_FILES,
            payload: {
              result: response.result,
              pages: response.pages,
              count: response.count,
              actualPage: page,
            },
          });
        } else {
          toast.warn(response.message.warning);
        }
      } else {
        toast.info('Seleccione una orden primero para ver los archivos');
      }
    } catch (err) {
      console.log({ getFilesByOrderErr: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function deleteFile(crmid, label) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const orderApi = new OrderApi();
      const response = await orderApi.deleteFile(crmid);
      if (response.success) {
        await dispatch(getFilesByOrder(1, ''));
        await toast.success(`Archivo: ${label} eliminado`);
      } else {
        const { message } = response;
        /* toast.warning(response.message.error || response.message.warning); */
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
    } catch (err) {
      console.log({ deleteFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function uploadFilesToOrder(files) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected: { crmid } } = cart;
      const res = await orderApi.uploadFile(crmid, files);
      const { success, message } = res;
      if (success) {
        toast.success(res.message.success);
        await dispatch(getFilesByOrder(1, ''));
      } else {
        /* toast.warn(res.message.error); */
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
    } catch (err) {
      console.log('uploadFiles err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function setOrderCustomer(account_id) {
  let toastId = null;
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      toastId = toast('Cambio de cliente ...', { type: toast.TYPE.INFO, progress: 0.25, autoClose: false });
      const { cart, order: { orderData: { cvid } } } = getState();
      const { orderSelected: { order } } = cart;
      const isOrder = order && order.hasOwnProperty('salesorder_no');
      const module = isOrder ? appModules.SalesOrder : appModules.Quotes;
      const res = await orderApi.setAccount(order.crmid, account_id);
      const { success, message } = res;
      if (success) {
        await toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          progress: 1,
          autoClose: 6000,
          render: message.success,
        });
        await dispatch(fetchOrderView(cvid));
        // await dispatch(fetchAllOrders());
        await dispatch(selectOrder(order.crmid));
        const { customer } = getState();
        const {
          selectedCustomer: {
            treebesdireccionenvioid,
            treebesdireccionfacturaid,
          },
        } = customer;
        if (treebesdireccionenvioid !== '' || treebesdireccionfacturaid !== '') {
          await dispatch(fetchAddressByCustomer(customer.selectedCustomer.crmid));
          await dispatch(openModal('alertAddressCustomer'));
        }
      } else {
        await toast.update(toastId, {
          type: toast.TYPE.WARNING,
          progress: 1,
          autoClose: 6000,
          render: message.warning || message.error,
        });
      }
    } catch (err) {
      console.log('setOrderCustomer err: ', err);
      await toast.update(toastId, {
        type: toast.TYPE.ERROR,
        progress: 0,
        autoClose: 6000,
        render: `Al cambiar el cliente de la orden/cotización surgio este error: ${err}`,
      });
    } finally {
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 6000);
      await dispatch(loaded('order'));
    }
  };
}

export function fetchFde(id) {
  const fdeApi = new FdeAPI();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      if (id) {
        const response = await fdeApi.get(id);
        const { success } = response;
        if (success) {
          const fde = response.result;
          await dispatch({
            type: FETCH_FDE,
            fde,
          });
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        const { cart, modal } = getState();
        const { orderSelected } = cart;
        if (Object.keys(orderSelected).length > 0) {
          const { order } = orderSelected;
          if (order) {
            const response = await fdeApi.get(order.crmid);
            const { success } = response;
            if (success) {
              const fde = response.result;
              await dispatch({
                type: FETCH_FDE,
                fde,
              });
            } else {
              toast.warn(response.messsage.error || response.messsage.warning);
            }
          }
        } else if (modal?.fdeModalIsOpen) {
          toast.info('Seleccione una orden primero para ver los flujos de efectivo');
        }
      }
    } catch (fetchFdeErr) {
      console.log({ fetchFdeErr });
      toast.warn(`Mientras cargabamos los flujos de efectivo surgió este error: ${fetchFdeErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function fetchFdeById(itemId) {
  const fdeApi = new FdeAPI();
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const response = await fdeApi.get(itemId);
      const { success } = response;
      if (success) {
        const fde = response.result;
        await dispatch({
          type: FETCH_FDE,
          fde,
        });
      } else {
        toast.warn(response.messsage.error || response.messsage.warning);
      }
    } catch (fetchFdeErr) {
      console.log({ fetchFdeErr });
      toast.warn(`Mientras cargabamos los flujos de efectivo surgió este error: ${fetchFdeErr}`);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function fetchMda() {
  const mdaApi = new MdaAPI();
  return async (dispatch, getState) => {
    await new Promise(resolve => setTimeout(resolve));
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { order } = orderSelected;
        const response = await mdaApi.get(order.crmid);
        const { success } = response;
        if (success) {
          const mda = response.result;
          await dispatch({
            type: FETCH_MDA,
            mda,
          });
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        console.log('orderSelected', orderSelected);
        toast.info('Seleccione una orden primero para ver los movimientos de almacén');
        dispatch(closeModal('deliver'));
      }
    } catch (fetchMdaErr) {
      console.log({ fetchMdaErr });
      toast.warn(`Mientras cargabamos los movimientos de almacén surgió este error: ${fetchMdaErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function fetchFactura() {
  const timbrarApi = new TimbrarApi();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { order } = orderSelected;
        const response = await timbrarApi.getList(order.crmid);
        const { success } = response;
        if (success) {
          const facturas = response.result;
          if (facturas.length > 0) {
            await dispatch({
              type: FETCH_FACTURAS,
              facturas,
            });
          } else {
            toast.info(response.empty_result_message);
          }
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        toast.info('Seleccione una orden primero para ver las facturas');
      }
    } catch (fetchFacturasErr) {
      console.log({ fetchFacturasErr });
      toast.warn(`Mientras obteniamos las facturas surgió este error: ${fetchFacturasErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function defineOrderColumns(config) {
  return async (dispatch) => {
    await dispatch({ type: DEFINE_COLUMN_ORDER, config });
  };
}

export function handleEditOrder() {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { enableEditOrder } = order;
    const el = pricebookHelper.getStorage();
    if (/* el.isCustomer && el.crmid !== '' &&  */!enableEditOrder) await dispatch(fetchAllProducts('Actualizando productos por cambio en listas de precios'));
    await dispatch({ type: HANDLE_EDIT_ORDER });
    await dispatch(updatePromotions());
  };
}

export function enableOrderEdit() {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { enableEditOrder } = order;
    const el = pricebookHelper.getStorage();
    if (el.isCustomer && el.crmid !== '' && !enableEditOrder) await dispatch(fetchAllProducts('Actualizando productos por cambio en listas de precios'));
    await dispatch({ type: ENABLE_EDIT_ORDER });
    await dispatch(updatePromotions());
  };
}

export function selectOrderAndOpenTicket(orderId) {
  return async (dispatch) => {
    await dispatch(selectOrder(orderId));
    await dispatch(openModal('tickets'));
  };
}
export function resetEditOrder() {
  return { type: RESET_EDIT_ORDER };
}

export function printOrder(crmid) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const orderApi = new OrderApi();

    try {
      const { authUser, cart } = getState();
      const { user: { pos_plantilla_pred_sell, almacenes } } = authUser;
      const { orderSelected } = cart;

      if (!orderSelected || !orderSelected.order) {
        toast.error('No se ha seleccionado una orden válida');
        throw new Error('No se ha seleccionado una orden válida');
      }

      const { order } = orderSelected;

      if (!pos_plantilla_pred_sell) {
        toast.error('No se encontró el ID de la plantilla de impresión');
        throw new Error('No se encontró el ID de la plantilla de impresión');
      }

      if (!almacenes || !Array.isArray(almacenes)) {
        toast.error('No se encontraron almacenes');
        throw new Error('No se encontraron almacenes');
      }

      // Find the correct almacen based on the order's store ID
      const almacen = almacenes.find(a => a.crmid === order.treebesalmid);

      if (!almacen) {
        toast.error('No se encontró el almacén correspondiente a la orden');
        throw new Error('No se encontró el almacén correspondiente a la orden');
      }

      // Get the first printer from the almacen
      const printerId = almacen.printers && almacen.printers.length > 0 ? almacen.printers[0].printer_id : null;

      if (!printerId) {
        toast.error('No se encontró una impresora para el almacén');
        throw new Error('No se encontró una impresora para el almacén');
      }

      const response = await orderApi.printOrder(crmid, pos_plantilla_pred_sell, printerId);
      console.log('response-123', response);
      if (response.success) {
        toast.success('Orden impresa exitosamente');
        /* dispatch({
          type: PRINT_ORDER,
          payload: response.result,
        }); */
      } else {
        toast.error(response.message || 'Error al imprimir la orden');
        throw new Error(response.message || 'Error al imprimir la orden');
      }
    } catch (error) {
      console.error('Error printing order:', error);
      toast.error(`Error al imprimir la orden: ${error.message}`);
    } finally {
      dispatch(loaded('order'));
    }
  };
}
