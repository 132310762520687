/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Slide,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import InputNumber from '../../../Input/Number';
import useSound from '../../../../hooks/sound/useSound';
import ProductAPI from '../../../../api/ProductAPI';
import AddLotes from '../../lotes/components/add-lotes/AddLotes';
import siImage from './aceptar.jpg';
import noImage from './cancelar.jpg';
import CodigoBarraModal from '../../modals/codigo-barra/CodigoBarraModal';
import ProductLocationModal, { TriggerType } from '../../modals/product-location/ProductLocationModal';
import { noKeyAllowed } from '../../../../helpers/tools';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ReceiverOrderProducts = ({
  // eslint-disable-next-line react/prop-types
  stock,
  products,
  changeInput,
  rest,
  add,
  loading,
  selectAll,
  selectNone,
  updateBarCode,
  updateProduct,
  receiverOrder,
}) => {
  const [openCompleted, setOpenCompleted] = useState(false);
  const { playSound } = useSound();
  const [scannedProduct, setScannedProduct] = useState(null);
  const [barCodes, setBarCodes] = useState([]);
  // const barCodes = products.map(p => p.codigo_barras || '');
  const scrolledActionEvent = new Event('scrolledAction');

  const getPartidas = () => {
    let count = 0;
    products.map((item) => {
      if (item.por_recibir !== 0) {
        count += 1;
      }
    });

    return count;
  };

  const getCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + item.por_recibir, 0);

    return count;
  };

  const getTotalCantidad = () => {
    let count = 0;
    count = products.reduce(
      (acc, item) => acc + parseFloat(item['por recibir']),
      0,
    );

    return count;
  };

  const processLotesSeries = (inputSelected, barCode) => {
    let shouldEnd = false;
    const findIndex = products.findIndex(prod => prod.id === inputSelected);
    if (findIndex !== -1) {
      // Product
      const item = products[findIndex];
      const isLote = item.pl_products_batch_status === 'pl_products_batch_status_lot';
      if (!item.lotesSeries.length) {
        // No products
        playSound('invalid');
        toast.error('Producto no encontrado');
        localStorage.setItem('barCode', '');
        localStorage.setItem('selectedInput', '');
        return;
      }
      item.lotesSeries.map((l) => {
        if (l.number === barCode.toUpperCase()) {
          if (isLote) {
            l.qty += 1;
          } else if (l.qty === 0) {
            l.qty += 1;
          } else {
            localStorage.setItem('barCode', '');
            setScannedProduct(item);
            toast.warn('La serie fue previamente escaneada');
            shouldEnd = true;
          }
        }
      });
      if (shouldEnd) {
        return;
      }
      localStorage.setItem('barCode', '');
      setScannedProduct(item);
      if (parseFloat(item['por recibir']) >= item.por_recibir + 1) {
        add(item.id);
        updateProduct(item);
        playSound('success');
        toast.success(`${isLote ? 'Lote' : 'Serie'} seleccionado`);
        localStorage.setItem('barCode', '');
      } else {
        playSound('error');
        toast.warn('Cantidad máxima agregada');
        localStorage.setItem('barCode', '');
      }
    } else {
      localStorage.setItem('barCode', '');
    }
  };

  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    // const barCodes = products.map(p => p.codigo_barras || '');
    const inputSelected = localStorage.getItem('selectedInput') || '';
    if (e.keyCode === 13 && barCode && products.length) {
      let barCodesProducts = [];
      products.map((p) => {
        if (Array.isArray(p.codigo_barras_array)) {
          barCodesProducts = [...barCodesProducts, ...p.codigo_barras_array];
        } else {
          barCodesProducts = [...barCodesProducts, p.codigo_barras_array];
        }
      });
      if (barCode === 'aceptar') {
        receiverOrder();
        setOpenCompleted(false);
        localStorage.setItem('barCode', '');
        return;
      }

      if (barCode === 'cancelar') {
        setOpenCompleted(false);
        localStorage.setItem('barCode', '');
        return;
      }
      // Tiene lotes o series
      if (inputSelected && !barCodesProducts.includes(barCode)) {
        processLotesSeries(inputSelected, barCode);
        return;
      }
      const findIndex = products.findIndex(
        prod => prod.codigo_barras_array.includes(barCode),
      );
      /* const findIndex = products.findIndex(
        prod => prod.codigo_barras === barCode,
      ); */
      if (findIndex !== -1) {
        // Product
        const item = products[findIndex];
        localStorage.setItem('scanedProductId', item.id);
        document.dispatchEvent(scrolledActionEvent);
        localStorage.setItem('selectedInput', item.id);
        if (item.TreebesLotes && item.TreebesLotes.length) {
          localStorage.setItem('barCode', '');
          localStorage.setItem('selectedInput', '');
          setScannedProduct(item);

          return;
        }

        if (parseFloat(item['por recibir']) >= item.por_recibir + 1) {
          add(item.id);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('Cantidad máxima agregada');
          localStorage.setItem('barCode', '');
        }
      } else {
        playSound('invalid');
        toast.error('Producto no encontrado');
        localStorage.setItem('barCode', '');
        localStorage.setItem('selectedInput', '');
      }
    } else if (!noKeyAllowed(e.key)) {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  const scanedCompleted = () => {
    const total = getTotalCantidad();
    const parcial = getCantidad();

    if (total && parcial && total <= parcial) {
      setOpenCompleted(true);
    }
  };

  const addRestItem = (id, action) => {
    if (action === 'add') {
      add(id);
    }
    if (action === 'rest') {
      rest(id);
    }
    localStorage.setItem('scanedProductId', id);
    document.dispatchEvent(scrolledActionEvent);
  };

  const handleBarCodes = () => {
    let codes = [];
    products.map((p) => {
      if (Array.isArray(p.codigo_barras_array)) {
        codes = [...codes, ...p.codigo_barras_array];
      } else {
        codes = [...codes, p.codigo_barras_array];
      }
    });
    setBarCodes(codes);
  };

  useEffect(() => {
    handleBarCodes();
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCode', '');
    scanedCompleted();
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [products]);

  useEffect(() => {
    document.addEventListener('scrolledAction', () => {
      const id = localStorage.getItem('scanedProductId');
      const element = document.getElementById(`${id}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      return () => {
        document.removeEventListener('scrolledAction', null, false);
      };
    });
  }, []);

  return (
    <div className="deliver__products" style={{ height: '100%' }}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center mr-2">
            <span className="mr-2">
              Partidas:
              {' '}
              {getPartidas()}
            </span>
            <span>
              Cantidad:
              {' '}
              {getCantidad()}
              {'de'}
              {getTotalCantidad()}
            </span>
          </div>
          <Button
            disabled={loading}
            className="mr-1"
            variant="outlined"
            onClick={selectAll}
          >
            Recibir todo
          </Button>
          <Button disabled={loading} variant="outlined" onClick={selectNone}>
            Recibir nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <div className="w-100 product-list" style={{ maxHeight: '95%' }}>
        {products.length > 0
          && products.map(item => (
            <div
              id={item.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10,
                border:
                  localStorage.getItem('scanedProductId') === item?.id
                    ? '8px solid red'
                    : 'inherit',
              }}
            >
              <div className={`${item.clase} refund_product `} key={item.id}>
                <div className="refund_product_stock">
                  {item?.inventario || 0}
                  <span>Stock</span>
                </div>
                <div className="refund_product_detail">
                  <div className="refund_product_title">
                    <Tooltip placement="left-start" title={item.producto || ''}>
                      <span>{item.producto}</span>
                    </Tooltip>
                  </div>
                  <div className="refund_product_desc">
                    {`Por Recibir: ${parseFloat(item['por recibir'])}`}
                  </div>
                  <div className="refund_product_desc">
                    {`codigo: ${item.codigo}`}
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mb-2">
                  <div>
                    <span>Código de barra</span>
                  </div>
                  <input
                    className="scanInput"
                    type="text"
                    readOnly
                    value={item.codigo_barras}
                  />
                  <div className="d-flex justify-content-center align-items-center">
                    <CodigoBarraModal product={item} updateBarCode={updateBarCode} />
                    <ProductLocationModal product={item} />
                  </div>
                </div>

                <div className="refund_product_qty">
                  <div>
                    <span style={{ whiteSpace: 'nowrap', marginRight: 15 }}>
                      {`Cantidad: ${parseFloat(item['por recibir'])}`}
                    </span>
                  </div>
                  <InputNumber
                    number={item.por_recibir}
                    disabledMinus={
                      parseFloat(item['por recibir']) === 0
                      || item.por_recibir === 0
                    }
                    disabledPlus={
                      parseFloat(item['por recibir']) === item.por_recibir
                    }
                    onClickMinus={() => addRestItem(item.id, 'rest')}
                    onClickPlus={() => addRestItem(item.id, 'add')}
                    circleDiameter="48px"
                    onChange={(value) => {
                      changeInput(parseFloat(value), item.id);
                    }}
                    onWarning={item.field === 0}
                    onDanger={item.por_recibir > item.cantidad}
                  />
                </div>
              </div>
              {[
                'pl_products_batch_status_lot',
                'pl_products_batch_status_serial',
              ].includes(item.pl_products_batch_status) && (
                <AddLotes
                  key={item.id}
                  product={item}
                  updateProduct={updateProduct}
                  totalAmount={parseFloat(item['por recibir'])}
                  scannedProduct={scannedProduct}
                  barCodes={barCodes}
                  isLote={
                    item.pl_products_batch_status
                    === 'pl_products_batch_status_lot'
                  }
                  isSerial={
                    item.pl_products_batch_status
                    === 'pl_products_batch_status_serial'
                  }
                />
              )}
            </div>
          ))}
      </div>

      <Dialog
        open={openCompleted}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenCompleted(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Recepción Completa
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ha terminado de seleccionar todos los productos disponibles, desea
            terminar?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setOpenCompleted(false)} color="primary">
            <img src={noImage} alt="No" />
          </Button>
          <Button
            onClick={() => {
              receiverOrder();
              setOpenCompleted(false);
            }}
            color="primary"
          >
            <img src={siImage} alt="Si" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ReceiverOrderProducts.propTypes = {
  stock: PropTypes.array,
  products: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  rest: PropTypes.func,
  add: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

ReceiverOrderProducts.defaultProps = {
  stock: [],
  products: [],
  loading: false,
  changeInput: () => {},
  rest: () => {},
  add: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default ReceiverOrderProducts;
