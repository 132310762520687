/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Menu,
  List,
  Radio,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { Store } from '@material-ui/icons';
// eslint-disable-next-line import/no-cycle
import { selectStore } from '../../redux/actions/authUserActions';
import { getItem } from '../../helpers/storage';
import { removeCustomer } from '../../redux/actions/customerActions';
import { pricebookHelper } from '../../helpers';
import { clearCart } from '../../redux/actions/cartActions';

const StoreSelect = ({
  title,
  loading,
  authUser,
  dispatchSelectStore,
  showSelectMessage = true,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }
  const stores = authUser.isAuth ? authUser.user.almacenes : [];
  const currentStore = authUser.isAuth ? authUser.user.config.store : {};
  const { user: { config: { pos_config_alm } } } = authUser;

  const sucursal = stores.filter(a => a.talm_tipo === 'Almacen/Sucursal');
  const repartidor = stores.filter(a => a.talm_tipo === 'Repartidor');

  React.useEffect(() => {
    if (authUser?.user?.almacenes.length && !authUser?.user?.config?.store) {
      dispatch(selectStore(
        authUser.user.almacenes[0].crmid,
        authUser.user.almacenes[0].almacen,
        authUser.user.almacenes[0].images[0] !== undefined ? authUser.user.almacenes[0].images[0] : '',
        authUser.user.almacenes[0].domicilio,
        authUser.user.almacenes[0].telefono,
        authUser.user.almacenes[0].email,
        authUser.user.almacenes[0].url_web,
        authUser.user.almacenes[0].talm_tpv_campos_en_ticket,
        authUser.user.almacenes[0].treebesdireccionenvioid,
        {},
        authUser.user.almacenes[0].pos_forzaenvio,
        authUser.user.almacenes[0].pos_currency_id,
      ));
    }
  }, []);
  React.useEffect(() => {
    if (!authUser?.user?.config?.store) {
      const lastStoreSelected = getItem(authUser?.user.comercia_cuenta, true);
      if (lastStoreSelected && lastStoreSelected !== JSON.stringify({})) {
        dispatch(
          selectStore(
            lastStoreSelected.crmid,
            lastStoreSelected.name,
            lastStoreSelected.image,
            lastStoreSelected.direccion,
            lastStoreSelected.telefono,
            lastStoreSelected.email,
            lastStoreSelected.url_web,
            lastStoreSelected.campos,
            lastStoreSelected.direccionid,
            {},
            lastStoreSelected.forzaEnvio,
            lastStoreSelected.currencyid,
          ),
        );
      }
    }
  }, [authUser]);

  return (
    <>
      <List component="nav" aria-label="StoreCatalog">
        <ListItem
          dense
          button
          aria-label={title}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls="FilterState"
        >
          <ListItemIcon>
            <Store />
          </ListItemIcon>
          <ListItemText
            primary={showSelectMessage ? title : ''}
            secondary={`${showSelectMessage ? 'Actual:' : ''} ${currentStore.name || currentStore.almacen}`}
          />
        </ListItem>
      </List>
      <Menu
        id="FilterState"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <>
          <MenuItem disabled>
            Sucursal
            <Divider />
          </MenuItem>
          {sucursal.map(store => (
            <>
              <MenuItem
                key={`key_radio_store_${store.crmid}`}
                disabled={loading}
                onClick={(event) => {
                  const { currentTarget } = event;
                  dispatchSelectStore(currentTarget, { ...store, pos_config_alm });
                  // Update client and pricebook
                  dispatch(removeCustomer());
                  pricebookHelper.setStorage({
                    crmid: '',
                    entity_label: '',
                    isCustomer: false,
                  });
                  // Clear cart
                  dispatch(clearCart());
                }}
                data-crmid={store.crmid}
                data-name={store.almacen}
                data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                data-campos={store.talm_tpv_campos_en_ticket}
                data-telefono={store.telefono}
                data-email={store.email}
                data-url_web={store.url_web}
                data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                data-direccionid={store.treebesdireccionenvioid}
                data-forzaenvios={store.pos_forzaenvio}
                data-currencyid={store.pos_currency_id}
              >
                <Radio
                  checked={store.crmid === currentStore.crmid}
                />
                <span>{store.almacen}</span>
              </MenuItem>
            </>
          ))}
          <MenuItem disabled>
            Repartidores
            <Divider />
          </MenuItem>
          {repartidor.map(store => (
            <>
              <MenuItem
                key={`key_radio_store_${store.crmid}`}
                disabled={loading}
                onClick={(event) => {
                  const { currentTarget } = event;
                  dispatchSelectStore(currentTarget, { ...store, pos_config_alm });
                }}
                data-crmid={store.crmid}
                data-name={store.almacen}
                data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                data-campos={store.talm_tpv_campos_en_ticket}
                data-telefono={store.telefono}
                data-email={store.email}
                data-url_web={store.url_web}
                data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                data-direccionid={store.treebesdireccionenvioid}
                data-forzaenvios={store.pos_forzaenvio}
                data-currencyid={store.pos_currency_id}
              >
                <Radio
                  checked={store.crmid === currentStore.crmid}
                />
                <span>{store.almacen}</span>
              </MenuItem>
            </>
          ))}
        </>
      </Menu>
    </>
  );
};

StoreSelect.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  dispatchSelectStore: PropTypes.func.isRequired,
};

StoreSelect.defaultProps = {
  loading: false,
  title: 'Selecciona un almacén ...',
};

export default StoreSelect;
